/*
 * We centralise download links logic here to avoid duplication
 */

import type { FileDescription } from '@orus.eu/backend/src/lib/file-description'
import type { StaticFileId } from '@orus.eu/dimensions'
import type {
  PersonaLessInsuranceCertificateRealEstateAgentsData,
  RiskCarrierLessInsuranceCertificateRealEstateSalesAgentsData,
} from '@orus.eu/documents/src/documents/insurance-certificate/insurance-certificate-props'
/**
 * For customers during the subscription
 */
export function getCurrentSubscriptionGeneralTermsDocumentDownloadLink({
  subscriptionId,
  file,
}: {
  subscriptionId: string
  file: FileDescription
}): string {
  const specifierName = getSpecifierNameFromFileDescription(file)
  return `/download/pending-subscription-general-terms/${subscriptionId}/${specifierName}.pdf`
}

/**
 * For customers after subscribing
 */
export function getMyGeneralTermsDocumentDownloadLink({
  subscriptionId,
  file,
}: {
  subscriptionId: string
  file: FileDescription
}): string {
  const specifierName = getSpecifierNameFromFileDescription(file)
  return `/download/my-general-terms/${subscriptionId}/${specifierName}.pdf`
}

/**
 * For admins anytime
 */
export function getAnyGeneralTermsDocumentDownloadLink({
  subscriptionId,
  file,
  isPartner,
  organization,
}: {
  subscriptionId: string
  file: FileDescription
  isPartner: boolean
  organization: string
}): string {
  const specifierName = getSpecifierNameFromFileDescription(file)
  if (isPartner) return `/download/${organization}/general-terms/${subscriptionId}/${specifierName}.pdf`
  return `/download/general-terms/${subscriptionId}/${specifierName}.pdf`
}

function getSpecifierNameFromFileDescription(specifier: string | FileDescription): string {
  if (typeof specifier === 'string') return specifier
  switch (specifier.data.type) {
    case 'static':
      return specifier.data.staticFileId
    case 'dynamic':
      return specifier.data.storedFileId
  }
}

export function getAgreedTermsDraftUrl(subscriptionId: string): string {
  return `/download/quote-document/agreed-terms?subscriptionId=${subscriptionId}`
}

export function getUserAgreedTermsDraftUrl(subscriptionId: string): string {
  return `/download/quote-document/user-agreed-terms?subscriptionId=${subscriptionId}`
}

export function getMyCertificateDownloadLink(inputSetId: string): string {
  return `/download/contracts/my-contracts/${inputSetId}/certificate.pdf`
}

export function getMySpecificCertificateTravelAgenciesDownloadLink(inputSetId: string): string {
  return `/download/contracts/my-contracts/${inputSetId}/specific-insurance-certificate-travel-agencies.pdf`
}

export function getMySpecificCertificateRealEstateSalesAgentsDownloadLink(
  inputSetId: string,
  data: RiskCarrierLessInsuranceCertificateRealEstateSalesAgentsData,
): string {
  const dataQueryParam = encodeURI(JSON.stringify(data))
  return (
    `/download/contracts/my-contracts/${inputSetId}/specific-insurance-certificate-real-estate-sales-agents.pdf?data=` +
    dataQueryParam
  )
}

export function getMySpecificCertificateRealEstateAgentsDownloadLink(
  inputSetId: string,
  data: PersonaLessInsuranceCertificateRealEstateAgentsData,
): string {
  const dataQueryParam = encodeURI(JSON.stringify(data))
  return (
    `/download/contracts/my-contracts/${inputSetId}/specific-insurance-certificate-real-estate-agents.pdf?data=` +
    dataQueryParam
  )
}

/**
 * url to download the contract (agreed-terms) for a given contract.
 * if a signatureId is provided will get the contract as in the version of the signature.
 * If no signatureId is provided will get the latest signed contract
 */
export function getMyContractDownloadLink(inputSetId: string, signatureId: string | null): string {
  const queryParams = signatureId === null ? '' : `?signatureId=${signatureId}`
  return `/download/contracts/my-contracts/${inputSetId}/contract.pdf` + queryParams
}

export function getMyPaymentScheduleDownloadLink(inputSetId: string): string {
  return `/download/contracts/my-contracts/${inputSetId}/payments-schedule.pdf`
}

export function getMyInvoiceDownloadLink(invoiceId: string): string {
  return `/download/my-invoices/${invoiceId}`
}

export function getMyReceiptDownloadLink(invoiceId: string): string {
  return `/download/my-receipts/${invoiceId}`
}

export function getAdminCertificateDownloadLink(inputSetId: string): string {
  return `/download/contracts/contracts/${inputSetId}/certificate.pdf`
}

export function getPartnerCertificateDownloadLink(inputSetId: string, organization: string): string {
  return `/download/${organization}/contracts/contracts/${inputSetId}/certificate.pdf`
}

export function getAdminSpecificCertificateTravelAgenciesDownloadLink(inputSetId: string): string {
  return `/download/contracts/contracts/${inputSetId}/specific-insurance-certificate-travel-agencies.pdf`
}

export function getPartnerSpecificCertificateTravelAgenciesDownloadLink(
  inputSetId: string,
  organization: string,
): string {
  return `/download/${organization}/contracts/contracts/${inputSetId}/specific-insurance-certificate-travel-agencies.pdf`
}

/**
 * url to download the contract (agreed-terms) for a given contract.
 * if a signatureId is provided will get the contract as in the version of the signature.
 * If no signatureId is provided will get the latest signed contract
 */
export function getAdminContractDownloadLink(inputSetId: string, signatureId: string | null): string {
  const queryParams = signatureId === null ? '' : `?signatureId=${signatureId}`
  return `/download/contracts/contracts/${inputSetId}/contract.pdf` + queryParams
}

export function getPartnerContractDownloadLink(
  inputSetId: string,
  signatureId: string | null,
  organization: string,
): string {
  const queryParams = signatureId === null ? '' : `?signatureId=${signatureId}`
  return `/download/${organization}/contracts/contracts/${inputSetId}/contract.pdf` + queryParams
}

export function getAdminScheduleDownloadLink(inputSetId: string): string {
  return `/download/contracts/contracts/${inputSetId}/payments-schedule.pdf`
}

export function getPartnerScheduleDownloadLink(inputSetId: string, organization: string): string {
  return `/download/${organization}/contracts/contracts/${inputSetId}/payments-schedule.pdf`
}

export function getAdminInvoiceDownloadLink(invoiceId: string): string {
  return `/download/invoices/${invoiceId}`
}

export function getAdminReceiptDownloadLink(invoiceId: string): string {
  return `/download/receipts/${invoiceId}`
}

export function getAdminMedDownloadLink(invoiceId: string): string {
  return `/download/med/${invoiceId}`
}

export function getStaticFileDownloadLink(staticFileId: StaticFileId): string {
  return `/download/static/${staticFileId}`
}

export function getCollectedFileDownloadLink(fileId: string): string {
  return `/rest/collected-files/${fileId}`
}
